import React from 'react';
import { Box, Alert } from '@mantine/core';
import { PiInfoFill } from 'react-icons/pi';

const ExpirationAlert = ({ hoy, expired, expiredDate }) => {
  const daysUntilExpiration = Math.ceil((expiredDate - hoy) / (1000 * 60 * 60 * 24));

  if (daysUntilExpiration > 20) return null;

  return (
    <Box>
      <Alert
        size="sm"
        variant="filled"
        autoContrast
        radius="lg"
        icon={<PiInfoFill className="w-16 h-16" />}
        color={
          hoy > expiredDate
            ? "red"
            : daysUntilExpiration <= 5
              ? "red"
              : daysUntilExpiration <= 10
                ? "yellow"
                : "blue"
        }
        title="Anuncio"
      >
        {hoy > expiredDate && "Servicio vencido"}
        {hoy === expiredDate && "El servicio vence hoy"}
        {hoy < expiredDate &&
          `El servicio vence el ${expired} (${daysUntilExpiration} días restantes)`}
      </Alert>
    </Box>
  );
};

export default ExpirationAlert;

import {Text, Box, Divider} from '@mantine/core'

const TicketFooter = () =>
{
  return (
    <div>
      <Box className='hidden print:block'>
        <Box className='flex flex-col gap-2 items-center justify-center my-2'>
          <Text fz="xs" className='hidden print:block text-center ticketFont capitalize'>Ubicación</Text>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>Calle 11 Plutarco Elias 8770-Local A, Zona Centro, 22000 Tijuana, B.C</Text>
        </Box>

        <Text fz="xs" className='hidden print:block text-center ticketFont'>Teléfonos</Text>
        <Box className='flex flex-row gap-2 items-center justify-center my-1'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>664 174 9089</Text>
        </Box>
        <Box className='pb-8 mb-4 flex flex-col'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>Horarios</Text>
          <Box className='flex flex-col gap-2 items-center justify-center'>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>LUNES A SABADO 08:00 A 20:00 HORAS</Text>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>DOMINGO CERRADO</Text>
            <br />
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Divider my={ 2 } className='text-gray-300'/>
    </div>
  )
}

export default TicketFooter
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Table, Card, Title, ActionIcon, Box } from '@mantine/core';
import
{
  PiEye, PiEyeClosed
} from 'react-icons/pi';

const OrderSummary = ({ orderInfo, orderDetail }) =>
{
  const [ collapse, setCollapse ] = useState(true)
  return (
    <>
      <Card withBorder shadow='md' my="md" radius="xl" >
        <Box className='flex flex-row justify-between items-center'>
          <Title order={ 4 }>Datos de la orden</Title>
          <ActionIcon variant='transparent'>
            {
              collapse ?
                <PiEyeClosed className='text-xl' onClick={ () => setCollapse(!collapse) } />
                :
                <PiEye className='text-xl' onClick={ () => setCollapse(!collapse) } />
            }
          </ActionIcon>
        </Box>
        { !collapse ? <Table>
          <Table.Tbody>
            <Table.Tr>

              <Table.Td colSpan={ 2 } className='text-right'>{ dayjs(orderDetail?.date).format('DD/MM/YYYY HH:mm a') }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Mesa:</Table.Td>
              <Table.Td>{ orderInfo.table }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Cliente:</Table.Td>
              <Table.Td>{ orderInfo.name }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Personas:</Table.Td>
              <Table.Td>{ orderInfo.persons }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Fecha de entrega:</Table.Td>
              <Table.Td>{ orderInfo.pickDate }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Hora:</Table.Td>
              <Table.Td>{ orderInfo.pickUpTime }</Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>Tel:</Table.Td>
              <Table.Td>{ orderInfo.phone }</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Dirección:</Table.Td>
              <Table.Td>{ orderInfo.address }</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table> : null }
      </Card>
    </>
  )
}

export default OrderSummary